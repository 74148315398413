import * as React from 'react';
import { Box, Button, Link, styled, Typography } from '@mui/material';
import KeyboardArrowRightRounded from '@mui/icons-material/KeyboardArrowRightRounded';
import { DOCUMENTATION_QUICKSTART_URL, TOOLPAD_LOCAL_ANNOUNCEMENT_URL } from '../../constants';
import { addUTMParamsToUrl, DEMO_CAMPAIGN_NAME } from '../../utils/ga';

const DeprecationBarContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.primaryDark[50],
  color: theme.palette.grey[700],
  display: 'flex',
  height: 60,
  justifyContent: 'space-between',
  paddingLeft: 20,
  paddingRight: 20,
}));

const addDeprecationBarUTMParamsToURL = (url: string): string =>
  addUTMParamsToUrl(url, {
    source: 'demo_bar',
    medium: 'organic_toolpad',
    campaign: DEMO_CAMPAIGN_NAME,
  });

export default function DeprecationBar() {
  return (
    <DeprecationBarContainer>
      <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
        Update!
      </Typography>
      <Typography variant="body2" textAlign="center">
        A{' '}
        <Link
          href={TOOLPAD_LOCAL_ANNOUNCEMENT_URL}
          target="_blank"
          underline="always"
          color="inherit"
        >
          big change
        </Link>{' '}
        is coming: we&apos;re making Toolpad focus much more on integrating with your IDE, while
        providing the ease of building UI fast with a drag and drop.
      </Typography>
      <Link href={addDeprecationBarUTMParamsToURL(DOCUMENTATION_QUICKSTART_URL)} target="_blank">
        <Button size="medium" variant="contained" endIcon={<KeyboardArrowRightRounded />}>
          Get started
        </Button>
      </Link>
    </DeprecationBarContainer>
  );
}
